import endsWith from 'lodash/endsWith';
import startsWith from 'lodash/startsWith';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { useTracking } from '../../Context';
import { isExternalLink } from '../../components/ExpiringNavLink';
import ImageIcon from '../../components/ImageIcon';
import { eventTags } from '../../core/trackers/events';
import { useCurrentUser } from '../../shared/store-user.hooks';
import { isFileUrl } from '../../utils/fileUtils';
import { replaceValues } from '../../utils/stringUtils';

const ActionIcons = {
  updatePassword: 'lock',
  logout: 'log out',
  updateProfile: 'check',
  editProfile: 'edit',
  cancelEditProfile: 'undo',
};

function getLinkProps(to, user) {
  if (!to) return undefined;
  const link = replaceValues(to, { user });
  if (startsWith(to, 'http') || endsWith(to, 'ics')) {
    return { as: 'a', href: link };
  }
  return { as: Link, to: link };
}

const ProfileActionButton = ({
  id,
  position = 'left',
  disabled = false,
  icon,
  label,
  onClick,
  to,
  ...props
}) => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const { trackEvent } = useTracking();

  const handleClick = () => {
    if (to) {
      if (isFileUrl(to)) {
        trackEvent(eventTags.DOWNLOAD_FILE, { uri: to, title: label, item: { _id: id } });
      } else {
        trackEvent(eventTags.CTA_GO_TO, { url: to, item: { _id: id, label, ...props } });
      }
    } else if (id === 'agendaPdf') {
      trackEvent(eventTags.DOWNLOAD_FILE, {
        uri: 'user-agenda-printed',
        title: label,
        item: { _id: id },
      });
    }

    if (onClick) onClick();
  };

  return (
    <Button
      {...getLinkProps(to, user)}
      size="small"
      icon
      floated={position}
      disabled={disabled}
      {...props}
      onClick={handleClick}
      className={`button--action button--action-${id}`}
    >
      <ImageIcon icon={icon || ActionIcons[id]} />
      &nbsp;
      <span>{label || t(`profile.actions.${id}`)}</span>
    </Button>
  );
};

ProfileActionButton.defaultProps = {
  disabled: false,
  icon: undefined,
  label: undefined,
  onClick: undefined,
  position: 'left',
  to: undefined,
};

ProfileActionButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right']),
  to: PropTypes.string,
};

export default ProfileActionButton;
