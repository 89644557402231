const fileExtensions = [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.png',
  '.jpg',
  '.jpeg',
  '.gif',
  '.txt',
  '.csv',
  '.mp4',
  '.mp3',
  '.ics',
];

export function isFileUrl(url: string): boolean {
  if (!url) return false;
  return fileExtensions.some((ext) => url.toLowerCase().endsWith(ext));
}
