import { validate } from 'email-validator';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Form, Header, Message } from 'semantic-ui-react';

import { withConfig } from '../config/config.context';
import { withDesign } from '../config/design.context';
import authService from '../core/services/auth.service';
import { getString } from '../utils';
import LoginLayout from './LoginLayout';

function normalizeString(string) {
  if (!string) return '';
  return string.trim();
}

class ForgottenPasswordScreen extends Component {
  state = {
    login: '',
    error: null,
    success: false,
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: normalizeString(value), message: null, error: null });
  };

  handleResetPassword = async () => {
    // eslint-disable-next-line react/destructuring-assignment
    const login = (this.state.login || '').trim();
    const res = await authService.forgottenPassword(login);
    if (res.success) {
      this.setState({
        message: `${getString('login.email-sent-to')} ${login}`,
        error: undefined,
      });
    } else {
      this.setState({ error: getString('login.error-wrong-login') });
    }
  };

  render() {
    const { design, config } = this.props;
    const loginPath = config?.screens?.login?.path || '/login';
    const { login, error, success, message } = this.state;
    if (success) {
      // redirect to login screen
      return <Redirect to={loginPath} />;
    }

    const variant = config?.screens?.login?.layout?.variant;
    const { primaryColor: color } = design;
    return (
      <LoginLayout screen="forgotten-password" variant={variant}>
        <div className="forgotten-password">
          <Header
            className="enter-login"
            as="h4"
            textAlign="center"
            style={{ visibility: error ? 'hidden' : 'visible' }}
          >
            {getString('login.please-enter-login-message')}
          </Header>

          {!message && (
            <Form.Input
              value={login}
              name="login"
              onChange={this.handleChange}
              // Don't show as error when the user hasn't typed much yet...
              error={login.length > 2 && !validate(login)}
              fluid
              icon="user"
              iconPosition="left"
              placeholder={getString('login.login')}
            />
          )}
          {!message && (
            <Button
              style={{ backgroundColor: color, color: 'white' }}
              fluid
              size="large"
              disabled={!validate(login)}
              onClick={this.handleResetPassword}
            >
              {getString('login.reset-password')}
            </Button>
          )}
          {message && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Message style={{ textAlign: 'left' }} content={message} />
            </div>
          )}
          {error && (
            <Message
              className="error-login"
              error
              style={{ display: 'block' }}
              header={getString('login.error')}
              content={error}
            />
          )}
          <br />
          <p>
            <Link to={loginPath}>{getString('login.sign-in')}</Link>
          </p>
        </div>
      </LoginLayout>
    );
  }
}

ForgottenPasswordScreen.propTypes = {
  design: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};

export default withConfig(withDesign(ForgottenPasswordScreen));
