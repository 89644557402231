import PropTypes from 'prop-types';
import React from 'react';
import { Form, Icon } from 'semantic-ui-react';

import useTranslations from '../../hooks/useTranslations';

const translationPrefix = 'login';

const ValidationMark = ({ valid }) => {
  if (!valid) return <Icon name="close" color="red" />;
  return <Icon name="checkmark" color="green" />;
};

const ResetPasswordFields = ({
  requireCurrentPassword,
  currentPassword,
  password,
  confirmPassword,
  constraints,
  onChange,
}) => {
  const { t } = useTranslations(translationPrefix);

  return (
    <>
      {requireCurrentPassword && (
        <Form.Input
          fluid
          icon="lock"
          iconPosition="left"
          placeholder={t('current-password')}
          type="password"
          name="currentPassword"
          value={currentPassword || ''}
          onInput={onChange}
        />
      )}
      <Form.Input
        fluid
        icon="lock"
        iconPosition="left"
        placeholder={t('new-password')}
        type="password"
        name="password"
        value={password || ''}
        onInput={onChange}
      />
      <Form.Input
        fluid
        icon="lock"
        iconPosition="left"
        placeholder={t('confirm-password')}
        type="password"
        name="confirmPassword"
        value={confirmPassword || ''}
        onInput={onChange}
      />
      <div style={{ marginBottom: 20, textAlign: 'justify' }}>
        {constraints.map(({ isValid, min, type }) => (
          <div key={type}>
            <ValidationMark valid={isValid} />
            {t(`password-constraints.${type}.message`, { min })}
          </div>
        ))}
      </div>
    </>
  );
};

ResetPasswordFields.defaultProps = {
  requireCurrentPassword: false,
  currentPassword: undefined,
  confirmPassword: undefined,
  constraints: [{ type: 'length', min: 8 }],
  password: undefined,
};

ResetPasswordFields.propTypes = {
  confirmPassword: PropTypes.string,
  constraints: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string })),
  onChange: PropTypes.func.isRequired,
  password: PropTypes.string,
  currentPassword: PropTypes.string,
  requireCurrentPassword: PropTypes.bool,
};

export default ResetPasswordFields;
