import { PlayerSdk } from '@api.video/player-sdk';
import { useEffect, useState } from 'react';

export const useApiVideoIframe = (containerId: string, videoId?: string): void => {
  const [loaded, setLoaded] = useState(false);

  const iframePlayer = document.getElementById(containerId) as HTMLIFrameElement;

  useEffect(() => {
    if (iframePlayer && !loaded && videoId) {
      const sdkPlayer = new PlayerSdk(`#${containerId}`, {
        id: videoId,
        hideTitle: true,
        autoplay: false,
        muted: false,
        chromeless: false,
        hideControls: false,
      });

      sdkPlayer.hideControls();
      sdkPlayer.showControls([
        'play',
        'progressBar',
        'volume',
        'fullscreen',
        'seekBackward',
        'seekForward',
      ]);

      setLoaded(true);
    }
  }, [iframePlayer]);
};
