import cx from 'classnames';
import { FC, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useThrottle, useWindowSize } from 'react-use';

import useEvent from '../../core/hooks/useEvent.hook';
import { isEllipsisActive } from '../../utils/text.utils';
import './TextWithOverflow.scss';

interface TextWithOverflowProps {
  text?: string;
  overflowEllipsis?: boolean;
  lineClamp?: number;
  withButton?: boolean;
  toggleOnClick?: boolean;
  onChangeOverflow?: (open: boolean) => any;
}

export const TextWithOverflow: FC<TextWithOverflowProps> = ({
  text,
  overflowEllipsis = true,
  lineClamp = 3,
  withButton = true,
  toggleOnClick = false,
  onChangeOverflow,
}: TextWithOverflowProps) => {
  const { t } = useTranslation();
  const [textIsEllipsis, setIsEllipsis] = useState(false);
  const [displayAllText, setDisplayText] = useState(false);

  const { width } = useWindowSize();
  const throttleWidth = useThrottle(width, 500);

  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!contentRef?.current) return;
    const isEllipsis = isEllipsisActive(contentRef.current);
    setIsEllipsis(isEllipsis);
  }, [throttleWidth]);

  const buttonLabel = displayAllText ? t('global-search.see-less') : t('global-search.see-more');

  const handleTextOpening = useEvent((e: SyntheticEvent) => {
    e.stopPropagation();
    const newState = toggleOnClick ? !displayAllText : true;
    setDisplayText(newState);
    if (onChangeOverflow) onChangeOverflow(newState);
  });

  return useMemo(() => {
    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          onClick={!withButton ? handleTextOpening : undefined}
          ref={contentRef}
          className={cx('text-wrapper', overflowEllipsis && !displayAllText && 'text-ellipsis')}
          style={{ WebkitLineClamp: lineClamp }}
        >
          {text ?? ''}
        </div>
        {withButton && (!displayAllText || toggleOnClick) && textIsEllipsis && (
          <button className="see-more-btn" onClick={handleTextOpening} type="button">
            {buttonLabel}
          </button>
        )}
      </>
    );
  }, [
    displayAllText,
    textIsEllipsis,
    overflowEllipsis,
    buttonLabel,
    text,
    lineClamp,
    toggleOnClick,
    withButton,
    handleTextOpening,
  ]);
};
