/* eslint-disable no-template-curly-in-string */
import cx from 'classnames';
import { omit } from 'lodash';
import { FC, memo } from 'react';

import CdnImage from '../../components/CdnImage';
import { TextWithOverflow } from '../../components/TextWithOverflow/TextWithOverflow';
import { VideoPlayIcon } from '../../components/VideoPlayIcon/VideoPlayIcon';
import { bem } from '../../core/design/bem';
import { Label } from '../../design-system/Label/Label';
import store from '../../shared/Store';
import { replaceValues } from '../../utils/stringUtils';
import { formatNumberToKiloString } from '../../utils/text.utils';
import { useSocialWallConfig } from '../social-wall-config.context';
import { Media, PublicationAuthor, PublicationInteractions } from '../types/SocialWallV2.types';
import { EmoteList } from './EmoteList';
import './PublicationCard.scss';
import { PublicationHeader } from './PublicationHeader';

interface PublicationCardProp {
  date?: string;
  message?: string;
  userInteractions: string[];
  media?: Media;
  thumbnail?: Media;
  interactions: PublicationInteractions;
  commentCount: number;
  onSizeChange?: (displayAllText: boolean) => void;
  author: PublicationAuthor;
  onClick?: (params?: any) => any;
  onInteractionClick?: (emote: string) => Promise<void>;
}
const css = bem('publication-card');

function computeImageStyles(displayedMedia: Media) {
  if (!displayedMedia) return undefined;
  const imgWidth = displayedMedia.width || 1;
  const imgHeight = displayedMedia.height || 1; // 1 instead of 0, so we never divide by 0...
  const isHorizontal = imgWidth > imgHeight;
  if (isHorizontal) {
    // Use padding to size block
    return { paddingTop: `${(imgHeight * 100) / imgWidth}%` };
  }
  const height = 300;
  return { height, width: (height * imgWidth) / imgHeight };
}

export const PublicationCard: FC<PublicationCardProp> = memo(
  ({
    author,
    media,
    date,
    interactions = {},
    commentCount = 0,
    message,
    userInteractions,
    thumbnail,
    onClick,
    onInteractionClick,
    onSizeChange,
  }) => {
    const { commentsEnabled, interactionsEnabled } = useSocialWallConfig();

    const isVideo = media?.mimeType?.split('/')?.[0] === 'video';
    const formattedCommentCount = formatNumberToKiloString(commentCount);

    const displayedMedia = thumbnail || media;
    const isHorizontal =
      displayedMedia && (displayedMedia.width || 0) > (displayedMedia.height || 0);

    const styles = displayedMedia ? computeImageStyles(displayedMedia) : undefined;
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div className={css()} onClick={onClick}>
        {/* TO DO: use event config to display user informations*/}
        <PublicationHeader
          user={author}
          date={date}
          description={replaceValues('{jobTitle} {company}', author)}
          sideDate
        />
        <div className={css('content')}>
          <TextWithOverflow
            text={message}
            overflowEllipsis={!!media}
            onChangeOverflow={onSizeChange}
          />
          {displayedMedia && (
            <div
              className={cx(
                'image-wrapper',
                isHorizontal ? 'image-wrapper__horizontal' : 'image-wrapper__vertical',
              )}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
              style={omit(styles, ['paddingTop'])}
            >
              <div style={styles}>
                <CdnImage src={displayedMedia?.uri} maxWidth={600} maxHeight={400} loading="lazy" />
                {isVideo && <VideoPlayIcon />}
              </div>
            </div>
          )}
        </div>
        <div className={css('footer', { end: !interactionsEnabled })}>
          <EmoteList
            disabled={!store.isLoggedIn()}
            interactions={interactions}
            userInteractions={userInteractions}
            onEmoteClick={onInteractionClick}
          />
          {commentsEnabled && (
            <Label
              flat
              value={formattedCommentCount}
              icon={{ size: 16, name: 'comment', color: 'grey' }}
            />
          )}
        </div>
      </div>
    );
  },
);
